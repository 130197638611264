<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="warp">
        <div class="left">
          <div class="goods-warp">
            <img :src="goods.original_image" class="goods-img">
            <div class="right-warp">
              <div class="title">{{goods.name}}</div>
              <div class="integral"><span>{{goods.points}}</span>邻里值</div>
              <el-button class="btn" type="primary" @click="exchangeClick">兑换</el-button>
            </div>
          </div>

          <div class="goods-content">
            <div class="title-warp">
              <i class="iconfont icon-lingjuan"></i>
              <div class="title">商品详情</div>
            </div>

            <div class="content-warp" v-html="goods.description">

            </div>
          </div>
        </div>

        <div class="right">
          <div class="ad">
            <img src="../../../assets/images/ad1.jpg">
          </div>
          <div class="ad">
            <img src="../../../assets/images/ad2.jpg">
          </div>
        </div>
      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
 import Footer2022 from '@/components/footer2022.vue'
import utils from '@/common/utils'

export default {
  name: "exchangeDetail",
  data () {
    return {
      goods: {}
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    '$route': {
      handler: function (to) {
        if (to.name == "exchangeDetail" && to.params.goodsId) {
          this.getGoodsData();
        }
      },
      immediate: true,
    }
  },
  methods: {
    getGoodsData: function () {
      let that = this;
      that.$axios.get(that.ocApi + 'points/products/' + that.$route.params.goodsId, {})
        .then(res => {
          if (res.data.status == 1) {
            that.goods = res.data.data
          } else {
            that.$message.error(res.data.message)
          }
        })
    },
    exchangeClick: utils.debounce(function () {
      let that = this;

      that.$confirm('确认兑换？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.exchangeGoods();
      }).catch(() => {
        return
      });
    }, 500, true),
    exchangeGoods: function () {
      let that = this;
      let products = [{ 'product_id': that.goods.product_id, 'quantity': '1', 'cart_select': '1', 'option': '' }]

      that.$axios.post(that.ocApi + 'points/cartorder/create', {
        products: JSON.stringify(products),
      })
        .then(res => {
          if (res.data.status == 1) {
            that.$message({
              type: 'success',
              message: '兑换成功!'
            });
            setTimeout(() => {
              that.$router.back()
            }, 1000)
          } else {
            that.$message.error(res.data.message)
          }
        })



    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 114px;
  padding-bottom: 50px;
  background-color: #f7f7f7;
}
.warp {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.warp .left {
  width: 841px;
}
.warp .right {
  width: 330px;
}

.warp .left .goods-warp {
  background-color: #fff;
  display: flex;
}
.warp .left .goods-warp .goods-img {
  width: 383px;
}
.warp .left .goods-warp .right-warp {
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  flex: 1;
}
.warp .left .goods-warp .right-warp .title {
  font-size: 24px;
  color: #434343;
  line-height: 24px;
}
.warp .left .goods-warp .right-warp .integral {
  font-size: 16px;
  color: #434343;
  line-height: 16px;
  margin-top: 19px;
}
.warp .left .goods-warp .right-warp .integral span {
  font-size: 24px;
  color: #00cb87;
  margin-right: 11px;
}
.warp .left .goods-warp .right-warp .price-warp {
  display: flex;
  color: #999999;
  font-size: 14px;
  margin-top: 29px;
  line-height: 14px;
}
.warp .left .goods-warp .right-warp .price-warp .price {
  margin-right: 32px;
}
.warp .left .goods-warp .right-warp .specification-warp {
  color: #434343;
  font-size: 14px;
  margin-top: 43px;
}
.warp .left .goods-warp .right-warp .specification-warp .specification {
  line-height: 14px;
  margin-top: 10px;
}
.warp .left .goods-warp .right-warp .btn {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 16px;
  padding: 10px 39px;
}
.warp .left .goods-content {
  margin-top: 20px;
  background-color: #fff;
  width: 100%;
}
.warp .left .goods-content .title-warp {
  display: flex;
  height: 57px;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 16px;
  color: #434343;
  line-height: 16px;
}
.warp .left .goods-content .title-warp .icon-lingjuan {
  font-size: 19px;
  color: #00cb87;
  margin-right: 7px;
  line-height: 19px;
}
.warp .left .goods-content .content-warp {
  width: 100%;
  padding: 0 30px 30px;
  box-sizing: border-box;
}
.warp .left .goods-content .content-warp img {
  width: 100%;
}
.warp .right .ad {
  width: 100%;
  margin-top: 21px;
}
.warp .right .ad img {
  width: 100%;
}
</style>